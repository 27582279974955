export enum UserRole {
    ROOT = "root",
    APP_ADMIN = "app_admin",
    USER = "user"
}

export enum ChartScaleOptions {
    LINEAR = "linear",
    LOG = "log"
}

export enum PesticideType {
    HERBICIDE = "herbicide",
    INSECTICIDE = "insecticide",
    FUNGICIDE = "fungicide",
    NEMATICIDE = "nematicide",
    ALL = "all"
}

export enum SprayMethod {
    COMPLETE = "complete",
    MAJORITY = "majority",
    BANDED = "banded",
    PART = "part",
    SPOT = "spot"
}

export interface BaseEntity {
    id: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface User extends BaseEntity {
    id: string;
    auth0Id?: string;
    currentGroupId: string;
    email: string;
    normalisedEmail: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    verified: boolean;
}

export interface CreatedByUserModel extends BaseEntity {
    id: string;
    firstName: string;
    lastName: string;
}

export interface CropModel {
    id: string;
    name: string;
    code: string;
}

export interface ConstituentModel {
    id: string;
    name: string;
    code: string;
    pubcrisCode: string;
    pesticideType: PesticideType;
    chemicalGroup: string;
    casNo: string;
    weight: number;
    ecotoxicityThreshold: number;
    defaultMeasureOfMobilityAndPersistance: number;
    defaultConcentration: number;
}

export interface CropConstituentModel extends ConstituentModel {
    cropId: string;
    defaultApplicationRate: number;
    defaultMeasureOfEffect: number;
    defaultAquaticRisk: number;
}

export interface MixConstituentModel extends CropConstituentModel {
    concentration: number;
    applicationRate: number;
    sprayMethod: SprayMethod;
    isEnabled: boolean;
}

export interface ExploreConstituentModel extends CropConstituentModel {
    concentration: number;
    applicationRate: number;
    measureOfEffect: number;
    aquaticRisk: number;
    isEnabled: boolean;
}

export interface AssessConstituentModel extends MixConstituentModel {
    measureOfEffect: number;
    aquaticRisk: number;
    appliedApplicationRate: number;
    appliedRiskUnits: number;
}

export interface ProductModel {
    id: string;
    name: string;
    pubcrisCode: string;
    constituents: ProductConstituentModel[];
    labelUrl: string;
}

interface ProductConstituentModel {
    constituentId: string;
    code: string;
    name: string;
    unit: string;
    concentration: number;
}

export interface MixModel {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    createdBy: CreatedByUserModel;
}
