import React from "react";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { User } from "types/models";
import { LogoutOptions } from "@auth0/auth0-spa-js";
import { fullname } from "utils/utils";

interface UserMenuProps {
    ptUser: User;
    logout: (o?: LogoutOptions) => void;
}

const UserMenu = ({ ptUser, logout }: UserMenuProps) => {
    const handleOpenHelpAndSupport = () => {
        const helpAndSupportURL = "https://pesticideprojector.net.au/";
        window.open(helpAndSupportURL, "_blank");
    };

    return (
        <Dropdown className="user-menu">
            <Dropdown.Toggle className="user-menu-dropdown-toggle">
                <FontAwesomeIcon icon={faUserCircle} size="2x" />
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="user-menu-dropdown-menu">
                <span className="name">{fullname(ptUser)}</span>

                <Dropdown.Divider />

                <Dropdown.Item onClick={() => handleOpenHelpAndSupport()} eventKey="1">
                    Help and Support
                </Dropdown.Item>

                <Dropdown.Item as={Link} to="/manage-account" eventKey="2">
                    Manage Account
                </Dropdown.Item>

                <Dropdown.Item onClick={() => logout()} eventKey="3">
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserMenu;
