import { faSortDown } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "Components/ERPIcon";
import { SelectOption } from "Components/Select/Select";
import React from "react";

export interface PTSelectToggleProps {
    selectedOption: SelectOption;
    onClick: (e: any) => void;
}

const PTSelectToggle = React.forwardRef((props: PTSelectToggleProps, ref: any) => {
    const { selectedOption, onClick } = props;

    return (
        <div
            className="pt-select"
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            ref={ref}
        >
            <span className="selected-option">{selectedOption.label}</span>
            <ERPIcon className="open-arrow" icon={faSortDown} size="2x"></ERPIcon>
        </div>
    );
});

export default PTSelectToggle;
