import Button from "Components/Button";
import EmptyRow from "Components/EmptyRow";
import React from "react";
import { ProductModel } from "types/models";

export interface ConstituentInfoProductListTableProps {
    products: ProductModel[];
    onProductApplyValues: (product: ProductModel) => void;
}

const ConstituentInfoProductListTable = ({ products, onProductApplyValues }: ConstituentInfoProductListTableProps) => {
    const handleViewLabel = (product: ProductModel) => {
        window.open(product.labelUrl, "_blank");
    };

    return (
        <div className="products-table">
            <table>
                <thead>
                    <tr>
                        <th>Trade name</th>
                        <th>Active ingredient</th>
                        <th>Concentration</th>
                        <th colSpan={1} style={{ width: "40%" }}></th>
                    </tr>
                </thead>

                <tbody>
                    {products.map(p => {
                        return (
                            <tr key={p.name}>
                                <td>{p.name}</td>

                                <td>
                                    <div className="list-cell">
                                        {p.constituents.map(c => {
                                            return <span key={c.name}>{c.name}</span>;
                                        })}
                                    </div>
                                </td>

                                <td>
                                    <div className="list-cell">
                                        {p.constituents.map(c => {
                                            return <span key={c.concentration}>{`${c.concentration} ${c.unit}`}</span>;
                                        })}
                                    </div>
                                </td>

                                <td>
                                    <div className="action-cell">
                                        <Button onClick={() => onProductApplyValues(p)}>Apply values</Button>
                                        <Button onClick={() => handleViewLabel(p)}>View label</Button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}

                    {products.length === 0 && <EmptyRow colSpan={4} message="No products found" />}
                </tbody>
            </table>
        </div>
    );
};

export default ConstituentInfoProductListTable;
