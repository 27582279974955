import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import TooltipIcon from "Components/TooltipIcon";
import { isNil } from "lodash";
import React, { useMemo } from "react";
import { roundToString } from "utils/utils";
import { useAssess } from "./AssessContext";
import { useMixView } from "./MixViewContext";
import MixViewResultChart from "./MixViewResultChart";

interface ComparisonChangeModel {
    isDifferent: boolean;
    message?: string;
    percentChange?: number;
}

const MixViewResult = () => {
    const { primaryMixScore } = useAssess();
    const { mixRiskScore, isPrimaryMix } = useMixView();

    const improvement: ComparisonChangeModel = useMemo(() => {
        if (isPrimaryMix) {
            return null;
        }

        if (primaryMixScore === mixRiskScore) {
            return {
                isDifferent: false
            };
        }

        if (primaryMixScore >= mixRiskScore) {
            return {
                isDifferent: true,
                message: "less than your first mix",
                percentChange: ((primaryMixScore - mixRiskScore) / primaryMixScore) * 100
            };
        }

        if (mixRiskScore >= primaryMixScore) {
            return {
                isDifferent: true,
                message: "more than your first mix",
                percentChange: ((mixRiskScore - primaryMixScore) / mixRiskScore) * 100
            };
        }
    }, [primaryMixScore, mixRiskScore]);

    return (
        <div className="result-container">
            <div className="risk-container">
                <span className="risk-title">{roundToString(mixRiskScore, 0)}</span>
                <div className="risk-label-container">
                    <span className="risk-label">Applied risk units per ha</span>
                    <TooltipIcon
                        tooltip="An arbitrary unit of risk per hectare of application, based on the active ingredient's aquatic risk score and the amount applied. Can be used as a common unit to compare between mixes."
                        icon={faQuestionCircle}
                        className="tooltip-icon"
                    />
                </div>
                {!isNil(improvement) && !improvement.isDifferent && (
                    <div className="improvement-label-container">
                        <span className="improvement-label">Same as your first mix</span>
                    </div>
                )}
                {!isNil(improvement) && improvement.isDifferent && (
                    <div className="improvement-label-container">
                        <span className="percent-label">{`${roundToString(improvement.percentChange, 0)}%`}</span>
                        <span className="improvement-label">{improvement.message}</span>
                    </div>
                )}
            </div>

            <MixViewResultChart />
        </div>
    );
};

export default MixViewResult;
