import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import TooltipIcon from "Components/TooltipIcon";
import React from "react";

const MixViewTableHead = () => {
    return (
        <thead>
            <tr>
                <th></th>
                <th></th>
                <th colSpan={2} className="centered-cell title-cell">
                    Default for active ingredient
                </th>
                <th colSpan={3} className="centered-cell title-cell blue-cell">
                    Your product
                </th>
                <th colSpan={1} className="centered-cell title-cell green-cell">
                    Your application
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>

            <tr>
                <th>On/off</th>
                <th>Active ingredient</th>
                <th>
                    App rate (kg/ha or L/ha)
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="The default application rate is the maximum application rate (kilograms per hectare, or litres per hectare) found across all researched product labels for the active ingredient. "
                        className="tooltip-icon"
                    />
                </th>
                <th>
                    Aquatic risk score
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="Indicates the likelihood that an active ingredient will reach a waterway and cause harmful effects. Calculated using the Relative Mobility or Persistence and Relative Measure of Effect based on the maximum application rate and broadcast application. "
                        className="tooltip-icon"
                    />
                </th>
                <th className="blue-cell input-cell">
                    Concentration (g/L or g/kg)
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="The concentration of the active ingredient (grams per litre, or grams per kilogram) as per your product’s label. "
                        className="tooltip-icon"
                    />
                </th>
                <th className="blue-cell input-cell">
                    App rate (kg/ha or L/ha)
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="The application rate per hectare (kilograms or litres) of the product that you applied/wish to apply as part of your pest control regime. "
                        className="tooltip-icon"
                    />
                </th>
                <th className="blue-cell">
                    Aquatic risk score
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="Indicates the likelihood that an active ingredient will reach a waterway and cause harmful effects. Calculated using the Relative Mobility or Persistence and Relative Measure of Effect based on the concentration and application rate you input. "
                        className="tooltip-icon"
                    />
                </th>
                <th className="green-cell centered-cell input-cell" style={{ width: 220 }}>
                    Spray method
                </th>
                <th className="centered-cell">
                    Total of active applied per ha (kg/ha)
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="Total kilograms of active ingredient applied per hectare, calculated using your product concentration divided by 1000, then multiplied by your application rate and adjusted by your spray method."
                        className="tooltip-icon"
                    />
                </th>
                <th className="centered-cell">
                    Applied risk units per ha
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="An arbitrary unit of risk per hectare of application, based on the active ingredient's aquatic risk score and the amount applied. Can be used as a common unit to compare between mixes."
                        className="tooltip-icon"
                    />
                </th>
                <th>Proportion of risk %</th>
                <th>Remove from mix</th>
            </tr>
        </thead>
    );
};

export default MixViewTableHead;
